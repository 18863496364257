/*colors : #0097b2 ; #0cc0df*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #f5f5f5;
}

body {
  background-color: #212121;
}

.header {
  padding: 10px;
  background-color: #1b1b1b;
}

.header_icon {
  background-image: url("../public/logo_mono.PNG");
  background-position: center;
  background-size: contain;
  height: 50px;
  width: 50px;
}

.container {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
}

.typewrite h1,
p {
  font-family: "Red Hat Display", sans-serif;
}

.container h1 {
  color: #0097b2;
}

.typewrite {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #1b1b1b;
  border-radius: 5px;
  width: 30%;
}
.typewrite p {
  border-right: 0.15em solid #0cc0df;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap; /* Keeps the content on a single line */
  letter-spacing: 0.15em; /* Adjust as needed */
}
.typewrite p:nth-child(1) {
  width: 7.3em;
  -webkit-animation: type 2s steps(40, end);
  animation: type 2s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.typewrite p:nth-child(2) {
  width: 11.5em;
  opacity: 0;
  -webkit-animation: type2 2s steps(40, end);
  animation: type2 2s steps(40, end);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.typewrite p:nth-child(3) {
  width: 25em;
  opacity: 0;
  -webkit-animation: type3 5s steps(20, end),
    blink 0.5s step-end infinite alternate;
  animation: type3 5s steps(20, end), blink 0.5s step-end infinite alternate;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.buttons {
  margin-top: 50px;
  a {
    padding: 10px;
    background-color: #0097b2;
    cursor: pointer;
    border-style: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    text-decoration: none;
  }

  a:hover {
    background-color: #0cc0df;
  }
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid #0cc0df;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid #0cc0df;
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid #0cc0df;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: 0.15em solid #0cc0df;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  100% {
    border-color: tranparent;
  }
}

.about-container {
  height: 60vh;
  background-color: #0097b2;
  padding: 20px 25px;
}

.about-container h1 {
  font-size: 5rem;
  font-family: "Micro 5", sans-serif;
}

.about-container p {
  text-align: start;
  width: 50%;
  font-size: 12pt;
  font-family: "Poppins", sans-serif;
}

.project-container {
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  height: 50vh;
  padding: 20px 25px;
  background-image: radial-gradient(#0097b2 1px, transparent 1px);
  background-size: 26px 26px;
  background-color: #212121;
  position: relative;
}

.folder {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100px;
}

.folder .icon {
  font-size: 5rem;
}

.folderPaper {
  background-color: #1b1b1b;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #0cc0df;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.heading h1 {
  font-family: "Micro 5", sans-serif;
  font-size: 30pt;
}

.close {
  font-size: 20pt;
  cursor: pointer;
}

.body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.body h1 {
  font-size: 15pt;
}

.body p {
  text-align: start;
}

.body ul {
  padding: 0 20px;
}

.link {
  display: flex;
  justify-content: flex-end;
}

.link a {
  background-color: #0097b2;
  padding: 10px;
  border-radius: 3px;
  text-decoration: none;
}

.social-container {
  padding: 10px 25px;
  background-color: #0097b2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu {
  width: 50px;
  height: 50px;
  background-color: #212121;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.menu div {
  background-color: #0cc0df;
  position: absolute;
}

.menu .horizontal {
  top: 50%;
  left: 0;
  right: 0;
  height: 1px; /* Adjust the thickness as needed */
  transform: translateY(-50%);
}

.menu .vertical {
  top: 0;
  bottom: 0;
  left: 50%;
  width: 1px; /* Adjust the thickness as needed */
  transform: translateX(-50%);
}

.modal {
  position: absolute;
  background-color: #0097b2;
  height: 300px;
  top: 155%;
  padding: 10px;
  width: 10%;
  border-radius: 5px;
}

.toolbar-container {
  width: 70%;
}

.toolbar {
  display: flex;
  gap: 30px;
}

.so-icon {
  font-size: 30pt;
  cursor: pointer;
}

.form {
  --input-focus: #2d8cf0;
  --font-color: #323232;
  --font-color-sub: #666;
  --bg-color: #fff;
  --main-color: #666;
  padding: 20px;
  background: #212121;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  border-radius: 5px;
  box-shadow: 4px 4px var(--main-color);
}

.title {
  color: #0cc0df;
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 25px;
}

.title span {
  color: var(--font-color-sub);
  font-weight: 600;
  font-size: 17px;
}

.input {
  width: 250px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid var(--main-color);
  background-color: var(--bg-color);
  box-shadow: 4px 4px var(--main-color);
  font-size: 15px;
  font-weight: 600;
  color: var(--font-color);
  padding: 5px 10px;
  outline: none;
}

.input::placeholder {
  color: var(--font-color-sub);
  opacity: 0.8;
}

.input:focus {
  border: 2px solid var(--input-focus);
}

.login-with {
  display: flex;
  gap: 20px;
}

.button-log {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px solid var(--main-color);
  background-color: var(--bg-color);
  box-shadow: 4px 4px var(--main-color);
  color: var(--font-color);
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 24px;
  color: #f5f5f5;
}

.button-log:active,
.button-confirm:active {
  box-shadow: 0px 0px var(--main-color);
  transform: translate(3px, 3px);
}

.button-confirm {
  margin: 50px auto 0 auto;
  width: 120px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid var(--main-color);
  background-color: var(--bg-color);
  box-shadow: 4px 4px var(--main-color);
  font-size: 17px;
  font-weight: 600;
  color: var(--font-color);
  cursor: pointer;
}

.footer{
  padding: 20px;
}

.footer p{
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  font-size: 15pt;
}

@media screen and (max-width: 500px) {
  .typewrite {
    width: 100%;
  }

  .about-container h1 {
    font-size: 3rem;
  }

  .about-container p {
    width: 100%;
    font-size: 0.9rem;
  }

  .folderPaper {
    width: 90%;
  }

  .heading h1 {
    font-size: 15pt;
  }

  .body h1 {
    font-size: 10pt;
  }

  .body p {
    font-size: 9pt;
  }

  .icon{
    font-size: 20px;
  }

  .modal {
    top: 150%;
    width: 30%;
  }

  .footer p{
    font-size: 8pt;
  }
}
